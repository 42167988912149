var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-alert",
        {
          style: {
            background: "" + _vm.typeList[_vm.type].bgColor,
            border: "1px solid " + _vm.typeList[_vm.type].borderColor,
          },
          attrs: {
            banner: _vm.banner,
            closable: _vm.closable,
            "show-icon": _vm.showIcon,
          },
          on: { close: _vm.onClose },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("a-icon", {
                    style: { color: "" + _vm.typeList[_vm.type].color },
                    attrs: {
                      type: _vm.typeList[_vm.type].name,
                      theme: "filled",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("template", { slot: "description" }, [_vm._t("content")], 2)],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }